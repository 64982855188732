// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"

Rails.start()
// ActiveStorage.start()
const images = require.context("../images", true)
const imagePath = (name) => images(name, true)

window.Rails = Rails
import "../javascripts";

import "../stylesheets/application";

import Vue from "vue";
import { BootstrapVue } from "bootstrap-vue";
Vue.use(BootstrapVue);

// vee validate
import { ValidationProvider, ValidationObserver, setInteractionMode, extend, localize, configure } from "vee-validate";
import zh_TW from "vee-validate/dist/locale/zh_TW.json";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

if(gon.locale == 'zh-TW'){
  localize("zh_TW", zh_TW);
} else {
  localize("en", en);
}
configure({
  classes: {
    valid: "is-valid",
    invalid: "is-invalid",
  },
});

Vue.component("ValidationObserver", ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

const load_event =
  typeof Turbolinks == "object" && Turbolinks.supported
    ? "turbolinks:load"
    : "DOMContentLoaded";
document.addEventListener(load_event, () => {
  /**
   * The following block of code may be used to automatically register your
   * Vue components. It will recursively scan this directory for the Vue
   * components and automatically register them with their "basename".
   *
   * Eg. ./components/OtherComponent.vue -> <other-component></other-component>
   * Eg. ./UI/ButtonComponent.vue -> <button-component></button-component>
   */
  const files = require.context("../components", true, /\.vue$/i);
  files.keys().map((key) => {
    const component = key
      .split("/")
      .pop()
      .split(".")[0];
    const import_source = key.split(".")[1];
    // With Lazy Loading
    Vue.component(component, () =>
      import(`../components${import_source}`).then((m) => m.default)
    );
  });
  const commons = require.context("../components/common", true, /\.vue$/i);
  commons.keys().map((key) => {
    const component = key
      .split("/")
      .pop()
      .split(".")[0];
    const import_source = key.split(".")[1];
    // With Lazy Loading
    Vue.component(component, () =>
      import(`../components/common${import_source}`).then((m) => m.default)
    );
  });
  const vm = new Vue({});
  vm.$mount("#app");
});
