<template>
  <div class="card border-dark border-2 mb-3 ratio ratio-16x9">
    <div class="card-body overflow-scroll">
      <h5 class="card-title text-center">{{ I18n.t('term') }}</h5>
      <term-tw :company_name="order.company_name" v-if="I18n.locale == 'zh-TW'"></term-tw>
      <term-en :company_name="order.company_name" v-else></term-en>
    </div>
  </div>
</template>

<script>
import TermTw from './term/TermTw';
import TermEn from './term/TermEn'
export default {
  component:{
    TermTw,
    TermEn
  },
  props: {
    order: {
      type: Object
    }
  },
  data(){
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.order'
      },
    }
  }
}
</script>

<style>

</style>