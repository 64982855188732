<template>
  <div>
    <p class="card-text">
        爰    {{ company_name || I18n.t('company_name', locale_scope) }}    (下稱客戶)擬向宏庭科技股份有限公司(下稱本公司)租用由美國Google有限責任公司及其關係企業(下統稱原廠)提供之Google Workspace企業協作工具服務(下稱本服務)，雙方茲同意約定成立本Google Workspace服務租用協議(下稱本協議)。
      </p>
      <p class="card-text">
        <ol>
          <li class="mb-3">
            協議有效期間：本協議自客戶於本公司線上提供之本服務租用頁面點選「同意」、「接受」、「accept」或其他類此字眼之選項時起生效，隨本服務有效期間屆滿未獲更新帳號或終止之日起而屆滿或終止。
          </li>
          <li class="mb-3">
            服務有效期間
            <ol>
              <li>
                除雙方另有約定外，本公司於本協議生效並受領全部費用後，將為客戶向原廠申請開通本服務帳號，本服務自帳號開通之日起1年內有效。
              </li>
              <li>
                於本服務有效期間屆滿之日起30天前，本公司得詢問客戶是否更新帳號，客戶應於15天內答覆以免產生本服務空窗期。
              </li>
            </ol>
          </li>
          <li class="mb-3">
            服務內容及費用：本服務之內容及所需費用，悉依本公司於網路或報價單以及原廠官網(參見<a href="https://workspace.google.com.tw/intl/zh-TW/" target="_blank">https://workspace.google.com.tw/intl/zh-TW/</a>)所載。
          </li>
          <li class="mb-3">
            原廠條款：客戶茲同意並了解「Google Workspace Agreement」(參見 <a href="https://workspace.google.com/terms/premier_terms_eea.html" target="_blank">https://workspace.google.com/terms/premier_terms_eea.html</a>)、「Google Workspace Service Level Agreement」(參見<a href="https://workspace.google.com/terms/sla.html" target="_blank">https://workspace.google.com/terms/sla.html</a>)等原廠於其官網上所頒訂之本服務相關條款及其不定期變更，皆為本協議之一部分。
          </li>
          <li class="mb-3">
            使用規範
            <ol>
              <li>
                客戶不得藉本服務從事違反所涉管轄權之國家或地區之法律，或有違法之虞之行為，否則應自負全部法律責任以及賠償本公司因此所受之全部損害，本公司並得逕行終止本協議。
              </li>
              <li>
                如因可歸責於客戶之事由致本公司受有權機關遂行調查、追訴或其他處分者，客戶應按本公司之請求立即提供必要協助(包括但不限於為本公司提出有利陳述或事證)，本公司並得逕行終止本服務。
              </li>
            </ol>
          </li>
          <li class="mb-3">
            賠償責任
            <ol>
              <li>
                任一方因違反本協議致他方受有直接損害者，應負賠償責任。本公司賠償責任以本協議總金額之5%為上限。
              </li>
              <li>
                原廠專有本服務相關技術與修改權利，本公司就本服務不負瑕疵擔保與經銷連帶責任。如因可歸責於原廠之事由致本服務之提供受有影響者，本公司將按客戶請求要求原廠按所頒訂之本服務相關條款予以賠償或補償。
              </li>
            </ol>
          </li>
          <li class="mb-3">
            協議終止
            <ol>
              <li>
                除本協議或雙方另有約定外，本服務因下列各款情形之一而終止：
                <ol type="a">
                  <li>
                    客戶如積欠本公司報酬或賠償金且經限期7日內清償而未清償者，本公司得終止本服務。
                  </li>
                  <li>
                    客戶如違反本協議其他規定且經限期7日內補正而未補正者，本公司得終止本服務。
                  </li>
                  <li>
                    本服務因天災、瘟疫、內亂、暴動、戰爭、動員、放射性污染、不可預見之國內外政府行為等不可抗力事由，或因原廠停止本服務業務等情事變更事由，致無法繼續被提供達2週者，客戶得通知本公司終止本服務。
                  </li>
                </ol>
              </li>
              <li>
                除有前項第三款情事且原廠已按比例返還價金予本公司者外，本公司不因本服務之終止，而返還已受領自客戶之價金或放棄對客戶之報酬請求權。
              </li>
              <li>
                本服務之終止不影響雙方間損害賠償請求權之行使。
              </li>
            </ol>
          </li>
          <li class="mb-3">
            轉讓之禁止：除經他方之事前書面同意外，任一方不得將因本協議或本服務所生權利義務之一部或全部，轉讓或再授權予任何契約外第三人。
          </li>
          <li class="mb-3">
            保密義務
            <ol>
              <li>
                任一方為本協議或附約之目的、準備行為或附隨義務，而以任何形式交付或揭露予他方之任何資料或資訊，凡標以「密」、「限閱」、「Confidential」或其他類此文義字眼，或依其性質屬營業秘密者，均為該一方之保密資訊；他方就該保密資訊除已經事前書面同意，或已為公眾所得知悉，或依法令負有揭示義務者外，應予保密。但依法令負有揭示義務者，應於合法範圍內向他方釋明俾其採行救濟程序。
              </li>
              <li>
                任一方如對內揭露他方保密資訊，應以因執行職務而有知悉必要者為限。任一方之履約輔助人如有洩密者，為該一方洩密。
              </li>
            </ol>
          </li>
          <li class="mb-3">
            其他及特約條款
            <ol>
              <li>
                客戶已完全同意並理解本協議內容，始於本公司線上提供之本服務租用頁面點選「同意」、「接受」、「accept」或其他類此字眼之選項。
              </li>
              <li>
                本協議之一部依法律或判決為無效或得撤銷者，他部之效力不因之受影響。
              </li>
              <li>
                客戶茲同意本公司為本協議目的，得將客戶本身及其交易資訊提供予契約外第三方(包括但不限於第三方支付業者)，使進行蒐集、處理與利用。
              </li>
              <li>
                客戶茲同意本公司及其關係企業為行銷之目的得對外分享客戶使用本服務情形，且授權本公司在不變更設計之情況下於官網、新聞稿、傳單或宣傳品中使用其商標。但除通知送達前已經本公司發布或交付廣告商者外，客戶得隨時以書面或以電子郵件寄至contact@nextlink.com.tw通知終止授權。
              </li>
            </ol>
          </li>
          <li>
            準據法及管轄法院：關於本協議之效力、解釋與其未盡之處，悉以中華民國法律為準據法；雙方如有涉訟，合意以臺灣臺北地方法院為第一審管轄法院。
          </li>
        </ol>
      </p>
  </div>
</template>

<script>
export default {
  props: {
    company_name: {
      type: String
    }
  },
  data(){
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.order'
      },
    }
  }
}
</script>

<style>

</style>