<template>
  <div>
    <p class="card-text">
        <b>Whereas</b>    {{ company_name || I18n.t('company_name', locale_scope) }} (the “Customer”, as a Party) has the intention to lease “Google Workspace” (the “Solution”), an enterprise collaboration solution provided by Google LLC and its affiliates (collectively referred to as “Google”), from Microfusion (HK) Technology Co., Limited (“Microfusion”, as the other Party), the Parties hereby agree this “Lease Agreement for Google Workspace” (this “Agreement”) which its terms and conditions are set as follows.
      </p>
      <p class="card-text">
        <ol>
          <li class="mb-3">
            <b>Agreement Effectiveness Term:</b> This Agreement shall be effective from the date the Customer clicks the option for “Agree”, “Accept” or any others similar to such meanings on the web page provided by Microfusion for the lease of the Solution, and shall expire or terminate as the date the Solution expires or terminates.
          </li>
          <li class="mb-3">
            Solution Effectiveness Term
            <ol>
              <li>
                Unless otherwise agreed by the Parties, Microfusion will, upon the Customer’s request, apply to Google for activating one or more accounts for the Solution (the “Account” or “Accounts”), provided that this Agreement becomes effective and Microfusion collects all charges from the Customer.
              </li>
              <li>
                Microfusion may, without obligation, query the Customer whether to apply for a new Account before 30 days from the expiration date of the existing Account, and the Customer shall give a feedback within 15 days to avoid any window period.
              </li>
            </ol>
          </li>
          <li class="mb-3">
            Contents & Charges: The contents and charges of the Solution shall be based on the description and specification in the quotation or website of Microfusion and the website of Google (see: <a href="https://workspace.google.com/intl/en/" target="_blank">https://workspace.google.com/intl/en/</a>).
          </li>
          <li class="mb-3">
            Terms & Conditions by Google: The Customer acknowledges and agrees “Google Workspace Agreement” (see: <a href="https://workspace.google.com/terms/premier_terms_eea.html" target="_blank">https://workspace.google.com/terms/premier_terms_eea.html</a>), “Google Workspace Service Level Agreement” (see: <a href="https://workspace.google.com/terms/sla.html" target="_blank">https://workspace.google.com/terms/sla.html</a>)and any other relevant terms along with irregularly revisions thereof, proclaimed by Google on its website, and that the above clauses constitutes part of the Agreement.
          </li>
          <li class="mb-3">
            Restriction of Use
            <ol>
              <li>
                The Customer shall not, by using the Solution, engage in any violation or suspicion of violation of the laws from where this Agreement may be governed, or Microfusion may unilaterally terminate this Agreement and the Customer shall be fully responsible and shall indemnify Microfusion from any loss caused by such violation.
              </li>
              <li>
                In the event that Microfusion suffers any investigation, inspection, prosecution or any other judicial or administrative disposition from the authorities due to the reason attributable to the Customer, Microfusion may unilaterally terminate the Solution and the Customer shall instantly provide for any necessary assistance, such as the statement or evidence favorable to Microfusion, upon request.
              </li>
            </ol>
          </li>
          <li class="mb-3">
            Damages
            <ol>
              <li>
                Either Party shall be liable for damages to the other for the loss directly caused by its breach of this Agreement. The amount of damages paid by Microfusion will not exceed 5% of the total price set in this Agreement.
              </li>
              <li>
                Microfusion shall not be jointly and severally liable to the existing defect of the Solution since Google exclusively possesses related rights and skills of the Solution.
              </li>
            </ol>
          </li>
          <li class="mb-3">
            Termination of the Solution
            <ol>
              <li>
                Unless otherwise specified in this Agreement or agreed by the Parties, the Solution may be terminated due to any of the following matters.
                <ol type="a">
                  <li>
                    In case the Customer owes Microfusion for any charges or damages and fails to rectify within 7 days as requested, Microfusion may unilaterally terminate the Solution.
                  </li>
                  <li>
                    In case the Customer breaches any other part of this Agreement and fails to rectify within 7 days as requested, Microfusion may unilaterally terminate the Solution.
                  </li>
                  <li>
                    In case the Solution is unable to be continuously provided to the Customer for 2 weeks due to force majeure events (such as act of God, pandemic, civil strife, riot, war, military mobilization, radioactive pollution and unpredictable domestic or foreign government activity) or change of circumstances (such as cancellation of the business by Google), the Customer may terminate the Solution.
                  </li>
                </ol>
              </li>
              <li>
                Unless Google refunds relevant charges pro rata to Microfusion due to the reason as specified in Subparagraph C of the previous Paragraph, Microfusion will not refund any charges to the Customer or abandon the right to claim for charges hereof due to termination of the Solution.
              </li>
              <li>
                The termination of the Solution shall not prejudice the rights to claim for damages between the Parties.
              </li>
            </ol>
          </li>
          <li class="mb-3">
            Prohibition of Assignment: Unless otherwise agreed by the other Party in writing, neither Party shall partly or fully transfer its right or obligation in this Agreement to any third party, nor shall authorize any third party to perform this Agreement.
          </li>
          <li class="mb-3">
            Confidentiality
            <ol>
              <li>
                Any data or information rendered or disclosed between the Parties in any method for the purposes of performance or preparation of this Agreement and its addendum, which is marked as “Secret”, “Restricted”, “Confidential” or any other such wordings or that may be seen as trade secret by its nature, shall be seen as the “Confidential Information”; Unless (a) a written approval by the other Party is collected, (b) the Confidential Information objectively could have been acknowledged by the public or (c) either Party is obligated to expose the Confidential Information, neither Party shall expose each other’s Confidential Information to any third party. The Party obligated to expose the Confidential Information shall inform the other Party, in the extent of the laws, of such matter so the other Party would seek necessary remedy.
              </li>
              <li>
                In case either Party has the need to internally expose the Confidential Information, it shall only expose to the person who necessarily need to be acknowledged due to his (her) duty.
              </li>
            </ol>
          </li>
          <li class="mb-3">
            Miscellaneous
            <ol>
              <li>
                The Customer fully acknowledges and agrees the contents of this Agreement, then clicks the option for “Agree”, “Accept” or any others similar to such meanings on the web page provided by Microfusion for the lease of the Solution
              </li>
              <li>
                In the event any part of this Agreement becomes ineffective or rescinded in accordance with the laws or a judicial judgment, the other parts shall not be prejudiced.
              </li>
              <li>
                The Customer agrees that Microfusion may, for the purpose of this Agreement, provide a third party, including and not limited to a third-party payment enterprise, with the information of the Customer and the transaction thereof so the third party may collect, handle and use such information to carry out the transaction between the Parties.
              </li>
              <li>
                The Customer agrees that Microfusion and its affiliates may, for the purpose of marketing, make the transaction between the Parties public, and authorizes Microfusion and its affiliates to use the Customer’s trademark on their websites, press releases, fly sheets or any other marketing materials thereof. Microfusion and its affiliates shall not alter the Customer’s trademark in any method and, unless such use has been proceeded or the trademark has been given to an advertisement contractor, the Customer may inform Microfusion to terminate such authorization at any time in writing or by emailing to <a href="mailto:contact@microfusion.hk">contact@microfusion.hk</a>.
              </li>
            </ol>
          </li>
          <li>
            Governing Law & Jurisdiction: The effectiveness and interpretation of this Agreement or that not specified in this Agreement shall be based on the laws of Hong Kong. In case a litigation is necessary, the Parties agree that the Court of Hong Kong shall be the jurisdiction court.
          </li>
        </ol>
      </p>
  </div>
</template>

<script>
export default {
  props: {
    company_name: {
      type: String
    }
  },
  data(){
    return {
      I18n,
      locale_scope: {
        scope: 'activerecord.attributes.order'
      },
    }
  }
}
</script>

<style>

</style>